<template>
  <div class="userinfoindex">
    <p class="title">个人资料</p>
    <div class="formbox">
      <div class="formitem flexbox">
        <img src="@/assets/img/avatar.png" alt="" v-if="!form.photo" />
        <img :src="form.photo" alt="" v-if="form.photo" />
        <el-upload
          class="avatar-uploader"
          action
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :http-request="
            (file) => {
              uploadSectionFile(file)
            }
          "
        >
          <div class="btnbox changeava">修改头像</div>
        </el-upload>
      </div>

      <div class="formitem flexbox">
        <span class="label">昵称</span>
        <el-input v-model="form.nikname" placeholder="输入" :disabled="disabled"></el-input>
      </div>

      <div class="formitem flexbox">
        <span class="label">性别</span>
        <el-select v-model="form.sex" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="formitem flexbox">
        <span class="label">手机号</span>
        <p class="text">{{ form.phone }}</p>
      </div>

      <!-- 个人账号显示 未认证 -->
      <div class="formitem flexbox">
        <span class="label">企业认证</span>
        <p class="text">{{ form.qiyerenzheng }}</p>
      </div>

      <div class="formitem flexbox">
        <span class="label">注册时间</span>
        <p class="text">{{ form.createTime }}</p>
      </div>

      <div class="formitem flexbox">
        <span class="label">会员身份</span>
        <p class="text">{{ form.shenfen }}会员</p>
      </div>
    </div>

    <div class="btnbox sava" @click="save">保存修改</div>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
import apiUrlLogin from '@/api/login'
export default {
  components: {},
  data() {
    return {
      form: {
        photo: '', // 用户头像
        nikname: '', // 昵称
        sex: '', // 性别
        phone: '',
        qiyerenzheng: '',
        createTime: '',
        shenfen: ''
      },
      options: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      disabled:false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getuserinfo();
  },
  methods: {
    // 修改头像
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw)
      this.form.photo = URL.createObjectURL(file.raw)
    },
    uploadSectionFile(param) {
      var fileObj = param.file
      var from = new FormData();
      from.append('file', fileObj);

      apiUrlLogin.upload(from).then((res) => {
        if (res.code == 0) {
          this.$message.success('上传成功')
          this.form.photo = res.url;
        }
      })
    },

    // 获取用户信息
    getuserinfo() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.userInfo(data).then((res) => {
        if (res.code == 0) {
          // this.form = res.data;
          Object.assign(this.form, res.data);
          
          if (res.data.usertype == '企业用户') {
            this.getqiyeinfo();
          } else {
            this.form.qiyerenzheng = '未认证'
            this.form.shenfen = '非'
            this.disabled = false;
          }

        } 
      })
    },
    // 企业审核详情 是否认证成功  status 空3 未提交  0未审核,审核中  1审核通过  2拒绝
    getqiyeinfo() {
      let data = {
        userId: localStorage.getItem('userId')
      }
      apiUrl.qiyeinfo(data).then((res) => {
        if (res.code == 0) {
          if (res.data.status == 1) {
            this.form.qiyerenzheng = '已认证'
            this.form.nikname =  res.data.name;
            this.form.createTime = res.data.createTime;
            this.form.shenfen = this.form.membersType;
            this.disabled = true;
          } else {
              this.form.qiyerenzheng = '未认证';
              this.form.shenfen = '非';
              this.disabled = false;
          }
        }
      })
    },

    // 保存
    save(){
      if(!localStorage.getItem('userId')){ return }

    
      let data = {
        id: localStorage.getItem('userId'),
        // nikname: this.form.nikname,
        photo:this.form.photo,
        phone:this.form.phone,
        sex:this.form.sex
      }

      if(this.disabled == false){
        data['nikname'] = this.form.nikname;
      }
      
      apiUrl.lsUseredit(data).then((res) => {
        if (res.code == 0) {

          localStorage.setItem("avatarUrl", this.form.photo);
          localStorage.setItem("nickName", this.form.nikname);

          this.$message({
            message:res.msg,
            type:'success'
          })
          this.getuserinfo();
          location.reload();
        } 
      })
    }
  }
}
</script>
<style lang='scss'>
//@import url(); 引入公共css类
.userinfoindex {
  padding: 33px 33px 67px 33px;
  background-color: #fff;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
  }
  .formbox {
    margin-top: 24px;
    .formitem {
      margin-bottom: 27px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .changeava {
        width: 80px;
        height: 32px;
        line-height: 32px;
        background: #305bfe;
        border-radius: 7px;
        margin-left: 24px;
        font-size: 14px;
      }
      .label {
        margin-right: 24px;
        font-size: 14px;
        color: #666666;
      }
      .text {
        font-size: 16px;
        color: #333333;
      }
      .el-input {
        width: 217px;
      }
    }
  }
  .sava {
    width: 133px;
    height: 42px;
    line-height: 42px;
    border-radius: 30px;
    margin-top: 67px;
  }
}
</style>